<template>
  <v-sheet class="mx-auto my-12" max-width="95%" v-if="user">
    <div class="text-center" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate />
      <br />

      {{$t("priscription.お客様の情報を確認中です。<br />処理が完了するまでお待ちください。")}}
    </div>
    <v-card v-if="loading === false">
      <v-card-title>
        {{ $t("ご購入履歴一覧") }}
      </v-card-title>
      <v-card-text>
        <div>
          {{ $t("選択するとご購入内容が確認いただけます。") }}
        </div>
        <v-list subheader two-line>
          <v-list-item
            v-for="temp_order in orders"
            :key="temp_order.id"
            @click="select_order(temp_order.id)"
          >
            <v-list-item-avatar>
              <v-icon class="blue lighten-1" dark>mdi-clipboard-text</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <span v-if="temp_order.payAt"
                >{{ $t("ご注文日") }}:
                {{ temp_order.payAt.toDate().toLocaleString() }}</span
              >
              <span v-if="temp_order.r_product_name"
                >{{ $t("右目") }}: {{ temp_order.r_product_name }}
                {{ temp_order.right_count }}{{ $t("箱") }}</span
              >
              <span v-if="temp_order.l_product_name"
                >{{ $t("左目") }}: {{ temp_order.l_product_name }}
                {{ temp_order.left_count }}{{ $t("箱") }}</span
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import { Firebase } from "../config/firebase";
export default {
  name: "about",
  data: () => ({
    loading: true,
    orders: [],
  }),
  computed: {
    user() {
      return this.$store.state.userModule.data[this.$store.getters.user.uid];
    },
  },
  created: async function () {
    this.loading = true;
    this.fetchUsers();
  },
  methods: {
    select_order: function (order_id) {
      this.$router.push({ name: "paid", params: { id: order_id } });
    },
    fetchUsers: async function () {
      await this.$store.dispatch(
        "userModule/fetchById",
        this.$store.getters.user.uid
      );
      await this.fetchOrders();
      this.loading = false;
    },
    fetchOrders: async function () {
      await Firebase.firestore()
        .collection("Orders")
        .where("user_id", "==", this.user.id)
        .where("status", "in", ["paied", "send", "cancel"])
        .orderBy("payAt", "desc")
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              let temp_item = doc.data();
              temp_item.id = doc.id;
              this.orders.push(temp_item);
            });
          }
        })
        .catch((error) => {
          console.log("Error getting Orders document:", error);
        });
    },
  },
};
</script>
